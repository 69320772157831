<template>
    <div class="d-flex justify-center align-center grey lighten-4" style="height: 100vh;">
        <v-card width="350">
            <v-card-title>Iniciar Sesión</v-card-title>
            <v-card-subtitle>Entre sus credenciales para iniciar sesión.</v-card-subtitle>
            <v-card-text>
                <v-alert v-model="alert" :type="alertType" dense>{{ alertMessage }}</v-alert>
                <v-form ref="form">
                    <v-text-field v-model="username" placeholder="username" prepend-inner-icon="mdi-account" outlined dense
                        :rules="rules.required"></v-text-field>
                    <v-text-field v-model="password" placeholder="password" prepend-inner-icon="mdi-lock" outlined dense :type="showPassword ? 'text' : 'password'"
                        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'" @click:append="showPassword = !showPassword" :rules="rules.required"
                        @keypress.enter="signIn"></v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn block depressed color="primary" :loading="loading" @click="signIn" style="text-transform: none;">Iniciar sesión</v-btn>
            </v-card-actions>
            <v-card-actions>
                <router-link class="text-center grey--text" style="width: 100%" to="/forgot-password">Olvidé mi contraseña</router-link>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        username        : '',
        password        : '',

        showPassword    : false,
        loading         : false,

        alert           : false,
        alertType       : 'warning',
        alertMessage    : '',

        rules           : {
            required: [v => !!v || 'este campo es requerido'],
        }
    }),
    computed: {
        ...mapGetters({
            isLoggedIn: 'isLoggedIn',
        })
    },
    created() {
        if( this.isLoggedIn )
            this.$router.push('/invoices');
    },
    methods: {
        ...mapActions({
            login       : 'login',
            authAction  : 'authAction'
        }),
        async signIn() {
            this.alert = false;

            // validate input
            if( !this.$refs.form.validate() )
                return;

            this.loading = true;
            let res = await this.login({ username: this.username, password: this.password });
            this.loading = false;
            
            if( res.status !== true ) {
                this.alertMessage = res.message;
                this.alertType = res.error ? 'error' : 'warning';
                this.alert = true;
                return;
            }
        }
    }
}
</script>
