<template>
    <div class="d-flex justify-center align-center grey lighten-4" style="height: 100vh;">
        <v-card width="350">
            <v-card-title>Actualizar contraseña</v-card-title>
            <v-card-text>
                <div>
                    La contraseña debe contener:
                    <ul style="list-style: initial;">
                        <li>Al menos 8 caracteres</li>
                        <li>Letras mayúsculas y minúsculas</li>
                        <li>Al menos un número</li>
                    </ul>
                </div>
            </v-card-text>
            <v-card-text>
                <v-alert v-model="alert" :type="alertType" dense>{{ alertMessage }}</v-alert>
                <v-form ref="form">
                    <v-text-field v-model="password" placeholder="Contraseña" prepend-inner-icon="mdi-lock-outline" outlined dense type="password"
                        :rules="[rules.required, rules.minLength, rules.numbers, rules.uppers, rules.lowers]"></v-text-field>
                    <v-text-field v-model="confirmation" placeholder="Confirmar contraseña" prepend-inner-icon="mdi-lock-outline" outlined dense type="password"
                        :rules="[rules.required, rules.match]" @keypress.enter.prevent="reset"></v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn block depressed color="primary"  style="text-transform: none;" :loading="loading" @click="reset">Recuperar</v-btn>
            </v-card-actions>
            <v-card-actions>
                <a class="text-center grey--text" style="width: 100%" @click="goBack">Volver atrás</a>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data() {
        return {
            password    : '',
            confirmation: '',

            loading     : false, 

            alert       : false,
            alertType   : 'warning',
            alertMessage: '',

            rules       : {
                required    : v => !!v || 'este campo es requerido',
                minLength   : v => v.length >= 8 || 'debe tener al menos 8 caracteres',
                numbers     : v => /[0-9]+/g.test( v ) || 'debe tener al menos un número',
                uppers      : v => /[A-Z]+/g.test( v ) || 'debe tener al menos una letra mayúscula',
                lowers      : v => /[a-z]+/g.test( v ) || 'debe tener al menos una letra minúscula',

                match       : v => v === this.password || 'la confirmación no es igual al password'
            }
        }
    },
    computed: {
        ...mapGetters({
            user    : 'user',
        })
    },
    methods: {
        ...mapActions({
            resetPassword   : 'resetPassword',
            logout          : 'logout',
        }),
        async reset() {
            if( !this.$refs.form.validate() )
                return;

            this.loading = true;
            let res = await this.resetPassword( this.password );
            this.loading = false;
            
            if( res.status !== true ) {
                this.alertMessage = res.message;
                this.alertType = res.error ? 'error' : 'warning';
                this.alert = true;
                return;
            }
        },
        goBack() {
            this.logout();
            this.$router.push('/signin');
        }
    }
}
</script>

<style lang="scss" scoped>

</style>