<template>
    <div class="d-flex justify-center align-center grey lighten-4" style="height: 100vh;">
        <v-card width="350">
            <v-card-title>Recuperar contraseña</v-card-title>
            <v-card-subtitle>Entre su email para recuperar su contraseña.</v-card-subtitle>
            <v-card-text>
                <v-alert v-model="alert" :type="alertType" dense>{{ alertMessage }}</v-alert>
                <v-form ref="form">
                    <v-text-field v-model="email" placeholder="Email" prepend-inner-icon="mdi-email-outline" outlined dense
                        :rules="rules.required" @keypress.enter.prevent="recover"></v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn block depressed color="primary"  style="text-transform: none;" :loading="loading" @click="recover">Recuperar</v-btn>
            </v-card-actions>
            <v-card-actions>
                <router-link class="text-center grey--text" style="width: 100%" to="/">Volver atrás</router-link>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data: () => ({
        email           : '',

        loading         : false,

        alert           : false,
        alertType       : 'warning',
        alertMessage    : '',

        rules           : {
            required: [v => !!v || 'este campo es requerido'],
        }
    }),
    methods: {
        ...mapActions({
            forgotPassword  : 'forgotPassword'
        }),
        async recover() {
            this.alert = false;

            if( !this.$refs.form.validate() )
                return;

            this.loading = true;
            let res = await this.forgotPassword( this.email );
            this.loading = false;
            
            this.email = '';

            if( res.status !== true ) {
                this.alertMessage = res.message;
                this.alertType = res.error ? 'error' : 'warning';
                this.alert = true;
                return;
            }

            this.alertMessage = 'Se ha enviado un email con una contraseña temporera.';
            this.alertType = 'success';
            this.alert = true;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>