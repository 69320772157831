<template>
    <div class="d-flex justify-center align-center grey lighten-4" style="height: 100vh;">
        <div class="text-center">
            <h1 class="text-h1">404</h1>
            <h3 class="headline">Not found</h3>

            <v-btn depressed color="primary" class="text-none mt-5" to="/invoices">
                <v-icon left>mdi-arrow-left</v-icon>
                Return home
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>

</style>